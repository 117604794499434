export class Log {
  public logType: 'MAIL_ALL_STUDENTS' | 'MAIL_SINGLE_STUDENT' | 'MAIL_ALL_SUPERVISORS' | 'MAIL_SINGLE_SUPERVISOR'
  public text: string
  public createdAt: string

  constructor (
    logType: 'MAIL_ALL_STUDENTS' | 'MAIL_SINGLE_STUDENT' | 'MAIL_ALL_SUPERVISORS' | 'MAIL_SINGLE_SUPERVISOR',
    text: string = '',
    createdAt: string = '') {
    this.logType = logType
    this.text = text
    this.createdAt = createdAt
  }
}
