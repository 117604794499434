import marked from 'marked'
import DOMPurify from 'dompurify'

export function processMarkdown (text: string): string {
  return DOMPurify.sanitize(marked(text))
}

export function twoDigit (possibleSingleDigit : number) : String {
  return (possibleSingleDigit < 10 ? '0' : '') + possibleSingleDigit
}
