














import { Component, Vue } from 'vue-property-decorator'
import changelogMarkDown from '../../../changelog.md'

@Component
export default class TheFooterContainer extends Vue {
  version : string = /\[(\d+\.\d+\.\d+)\]/.exec(changelogMarkDown)![1]

  async created (): Promise<void> {
  }
}
