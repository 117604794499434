























































import { Api } from '@/api'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Principal } from '../types/principal'

@Component
export default class TheHeaderContainer extends Vue {
  user: Principal | null = null;

  // Specify whether non-logged-in users should be redirected to login page
  @Prop() redirect?: boolean;

  async created (): Promise<void> {
    try {
      // null if not logged in
      const user = await Api.getCurrentUser()
      if (user) {
        this.user = user
      } else if (this.redirect !== false) {
        location.href = 'login.html'
      }
    } catch (error) {
      this.$bvModal.msgBoxOk('Could not load user: ' + error.message, { title: 'Error' })
    }
  }
}
