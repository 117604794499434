import { Type, instanceToPlain } from 'class-transformer'
import { ExamElement } from './exam-element'
import { Group } from './group'
import { Log } from './log'
import { twoDigit } from '@/util'
import _ from 'lodash'

export class ExamDate {
  constructor (
   public year: string = '0',
   public month: string = '0',
   public day: string = '0',
   public hour: string = '0',
   public minute: string = '0') { }

  get asString (): string {
    const day = Number(this.day)
    const month = Number(this.month)
    const year = this.year

    const hour = Number(this.hour)
    const minute = Number(this.minute)

    return `${twoDigit(day)}.${twoDigit(month)}.${year} ${twoDigit(hour)}:${twoDigit(minute)}`
  }

  get asDate (): Date {
    const date = new Date(
      Date.UTC(
        Number(this.year),
        Number(this.month) - 1,
        Number(this.day),
        Number(this.hour) - 2, // TODO timezone and exams in the middle of the night
        Number(this.minute)
      )
    )

    // Check if offset is correct
    // Format: HH:mm:ss
    const time = date.toLocaleTimeString('de-AT', { timeZone: 'Europe/Vienna' })
    if (Number(time.split(':')[0]) !== Number(this.hour)) {
      date.setUTCHours(Number(this.hour) - 1)
    }

    return date
  }
}

export class Exam {
  public key: string
  public name: string
  public creatorMail: string

  @Type(() => ExamDate)
  public start: ExamDate

  @Type(() => ExamDate)
  public end: ExamDate

  @Type(() => ExamElement)
  public blocks: ExamElement[]

  @Type(() => Group)
  public groups: Group[]

  public participantMailTemplate: string
  public supervisorMailTemplate: string

  @Type(() => Log)
  public logs: Log[]

  public privateKey: string

  constructor (
    key: string = '',
    name: string = '',
    creatorMail: string = '',
    start: ExamDate = new ExamDate(),
    end: ExamDate = new ExamDate(),
    blocks: ExamElement[] = [],
    groups: Group[] = [],
    participantMailTemplate: string = '',
    supervisorMailTemplate: string = '',
    logs: Log[] = [],
    privateKey: string = '') {
    this.key = key
    this.name = name
    this.creatorMail = creatorMail
    this.start = start
    this.end = end
    this.blocks = blocks
    this.groups = groups
    this.participantMailTemplate = participantMailTemplate
    this.supervisorMailTemplate = supervisorMailTemplate
    this.logs = logs
    this.privateKey = privateKey
  }

  get hasEnded () : boolean {
    return this.end.asDate < new Date()
  }

  equals (otherExam : Exam) : boolean {
    const a = instanceToPlain(this)
    const b = instanceToPlain(otherExam)

    return _.isEqual(a, b)
  }
}
