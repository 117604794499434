import axios, { AxiosError } from 'axios'
import { plainToInstance } from 'class-transformer'
import { ApiResponse } from './types/api'
import { Principal } from './types/principal'

const DEBUG_MODE = process.env.NODE_ENV !== 'production'

export class Api {
  public static async getArray<T> (type: { new(): T}, url: string) {
    let axiosResponse
    try {
      axiosResponse = await axios.get<ApiResponse<T[]>>(url)
      if (DEBUG_MODE) {
        console.log('Response for getArray():')
        console.log(axiosResponse)
      }
    } catch (error) {
      if (DEBUG_MODE) {
        this.printError(error)
      }
      throw error
    }

    // apiResponse has "success", "cause", "message", and in the case of success "data"
    const apiResponse = axiosResponse.data

    if (!apiResponse.success) {
      if (DEBUG_MODE) {
        console.log('Response indicates unsuccessful operation, throwing error')
      }
      throw new Error(`${apiResponse.cause} - ${apiResponse.message}`)
    }

    const tsObjects : T[] = plainToInstance(type, apiResponse.data)
    if (DEBUG_MODE) {
      console.log('Returning data:')
      console.log(tsObjects)
    }
    return tsObjects
  }

  public static async get<T> (type: { new(): T}, url: string) {
    let axiosResponse
    try {
      axiosResponse = await axios.get<ApiResponse<T>>(url)
      if (DEBUG_MODE) {
        console.log('Response for get():')
        console.log(axiosResponse)
      }
    } catch (error) {
      if (DEBUG_MODE) {
        this.printError(error)
      }
      throw error
    }

    // apiResponse has "success", "cause", "message", and in the case of success "data"
    const apiResponse = axiosResponse.data

    if (!apiResponse.success) {
      if (DEBUG_MODE) {
        console.log('Response indicates unsuccessful operation, throwing error')
      }
      throw new Error(`${apiResponse.cause} - ${apiResponse.message}`)
    }

    const tsObject = plainToInstance(type, apiResponse.data)
    if (DEBUG_MODE) {
      console.log('Returning data:')
      console.log(tsObject)
    }
    return tsObject
  }

  // TODO Create one method per API endpoint here, not only getCurrentUser()
  public static async getCurrentUser () {
    return await Api.get(Principal, '/api/user/current')
  }

  public static async post<T> (url: string, body?: unknown) {
    let axiosResponse
    try {
      axiosResponse = await axios.post<ApiResponse<T>>(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (DEBUG_MODE) {
        console.log('Response for post():')
        console.log(axiosResponse)
      }
    } catch (error) {
      if (DEBUG_MODE) {
        this.printError(error)
      }
      throw error
    }

    // apiResponse has "success", "cause", "message", and in the case of success "data"
    const apiResponse = axiosResponse.data

    if (!apiResponse.success) {
      if (DEBUG_MODE) {
        console.log('Response indicates unsuccessful operation, throwing error')
      }
      throw new Error(`${apiResponse.cause} - ${apiResponse.message}`)
    }

    if (DEBUG_MODE) {
      console.log('Returning data:')
      console.log(apiResponse.data)
    }
    return apiResponse.data
  }

  private static printError (error: Error | AxiosError) :void {
    if (axios.isAxiosError(error)) {
      console.log('Axios Error')
      if (!error.response) {
        console.log('Reason: Network Error (Server not reachable)!')
        console.log('Error:')
        console.log(error)
      } else {
        console.log('Error response with HTTP status ' + error.response.status)
        console.log('Error:')
        console.log(error)
        console.log('Response:')
        console.log(error.response)
      }
    } else {
      console.log('Stock Error')
      console.log('Error:')
      console.log(error)
    }
  }
}
