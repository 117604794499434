
import { Component, Prop } from 'vue-property-decorator'
import { ExerciseAnswer, Submission } from '@/types/submission'
import { Api } from '@/api'
import BaseExamVisualization from '@/components/BaseExamVisualization.vue'

@Component
export default class ExamVisualization extends BaseExamVisualization {
  @Prop()
  readonly code!: string;

  finalSubmitText = '(Final!)'

  inputStudentName = '';
  submissionMode = false;
  submissionResponse = {} as any;

  async submit (): Promise<void> {
    const vm = this
    const data : Submission = {
      examKey: vm.exam.key,
      matNr: vm.matNr,
      studentName: vm.inputStudentName,
      code: vm.code,
      exerciseAnswers: vm.exam.blocks.map(function (block: any, index: number) {
        return vm.buildAnswer(index)
      })
    }

    try {
      const _acceptedSubmission = await Api.post<Submission>('/api/submission/submit', data)
      await this.$bvModal.msgBoxOk(
        'Your exam was successfully submitted.\n' +
          'Please notify your exam supervisor that you finished.\n' +
          'The exam will close now.',
        { title: 'Success' }
      )
      setTimeout(() => { window.location.replace('/thanks.html') }, 1000)
    } catch (err) {
      vm.handleSubmitError(err)
    }
  }

  private handleSubmitError (err: any) {
    if (err.message === 'Network Error') {
      this.$bvModal.msgBoxOk(
        'Could not contact server (server down?)! Please contact the admin!',
        {
          title: 'Server not reachable'
        })
    } else {
      if (err.response) {
        // Some server error (404, 500, etc.)
        this.$bvModal.msgBoxOk(
          `Server reached, but server-internal error: ${err.message}. Please contact the admin!`,
          {
            title: 'Error'
          })
      } else {
        // Server was reachable, genertated response, but response was "success: false"
        this.$bvModal.msgBoxOk(
          'Server reached, but submission was not allowed: ' + err.message,
          {
            title: 'Error'
          })
      }
    }
  }

  private buildAnswer (index: number) : ExerciseAnswer {
    const vm = this
    const block = vm.exam.blocks[index]
    const answer : ExerciseAnswer = new ExerciseAnswer(index, block.kind)
    switch (block.kind) {
      case 'TEXT_QUESTION':
        answer.text = vm.textAreas[index].getValue()
        break
      case 'CODE_QUESTION':
        answer.code = vm.textAreas[index].getValue()
        break
      case 'IMAGE_QUESTION':
        answer.svg = document.getElementById('canvas_' + index)?.outerHTML
        break
      case 'SINGLE_CHOICE_QUESTION': {
        const choice = vm.singleChoices[index]
        if (choice) {
          answer.selectedAnswer = choice
        } else {
          answer.selectedAnswer = ''
        }
        break
      }
      case 'MULTIPLE_CHOICE_QUESTION': {
        const choices = vm.multiChoices[index]
        if (choices) {
          answer.selectedAnswers = choices
        } else {
          answer.selectedAnswers = []
        }
        break
      }
    }
    return answer
  }
}
