import { processMarkdown } from '@/util'
import { Type } from 'class-transformer'

export class ExamElementChoice {
  constructor (
        public answer: string = '',
        public correct: boolean = false
  ) { }

  get answerAsHTML (): string {
    return processMarkdown(this.answer)
  }
}

export type ExamElementKind =
  'TEXT' |
  'TEXT_QUESTION' |
  'CODE_QUESTION' |
  'IMAGE_QUESTION' |
  'SINGLE_CHOICE_QUESTION' |
  'MULTIPLE_CHOICE_QUESTION'

export type CodeLanguage =
  'ASM' |
  'C' |
  'CSS' |
  'C_SHARP' |
  'C_PLUS_PLUS' |
  'HTML' |
  'JAVA' |
  'JAVASCRIPT' |
  'LISP' |
  'PYTHON' |
  'SHELL' |
  'SQL' |
  'TYPESCRIPT' |
  'XML'

export const examElementKindNames : { [key in ExamElementKind] : string } = {
  TEXT: 'Text block',
  TEXT_QUESTION: 'Text question',
  CODE_QUESTION: 'Code question',
  IMAGE_QUESTION: 'Drawing question',
  SINGLE_CHOICE_QUESTION: 'Single choice question',
  MULTIPLE_CHOICE_QUESTION: 'Multiple choice question'
}

export const codeLanguageNames : { [key in CodeLanguage] : string } = {
  'ASM': 'Assembly',
  'C': 'C',
  'CSS': 'CSS',
  'C_PLUS_PLUS': 'C++',
  'C_SHARP': 'C#',
  'HTML': 'HTML',
  'JAVA': 'Java',
  'JAVASCRIPT': 'JavaScript',
  'LISP': 'Lisp',
  'PYTHON': 'Python',
  'SHELL': 'Shell',
  'SQL': 'SQL',
  'TYPESCRIPT': 'TypeScript',
  'XML': 'XML'
}

export class ExamElement {
  public key: string
  public kind: ExamElementKind
  public text: string
  public points: number
  @Type(() => ExamElementChoice)
  public choices: ExamElementChoice[]

  public codeLanguage: CodeLanguage

  constructor (
    key: string = '',
    kind: ExamElementKind = 'TEXT',
    text: string = '',
    points: number = 0,
    choices: ExamElementChoice[] = [],
    codeLanguage: CodeLanguage = 'JAVA') {
    this.key = key
    this.kind = kind
    this.text = text
    this.points = points
    this.choices = choices
    this.codeLanguage = codeLanguage
  }

  get textAsHTML () : string {
    let pointsText = ''
    if (this.points > 0) {
      if (this.points === 1) {
        pointsText = '*' + this.points + ' Punkt | ' + this.points + ' Point*\n\n'
      } else {
        pointsText = '*' + this.points + ' Punkte | ' + this.points + ' Points*\n\n'
      }
    }
    return processMarkdown(pointsText + this.text)
  }
}
