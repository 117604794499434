



















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SaveCancelButtons extends Vue {
  @Prop({ default: true }) isSaved !: boolean
  @Prop({ default: false }) hasStarted !: boolean

  @Emit()
  cancel () {
  }

  @Emit()
  save () {
  }
}
