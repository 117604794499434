export class Principal {
  public email: string
  public firstName: string
  public lastName: string
  public userRole: string
  constructor (
    email: string = '',
    firstName: string = '',
    lastName: string = '',
    userRole: string = '') {
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.userRole = userRole
  }
}
