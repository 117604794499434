












































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Exam } from '@/types/exam'
import { CodeLanguage } from '@/types/exam-element'
import * as ace from 'ace-builds'
// Import initial theme and mode so we don't have to wait for 2 extra HTTP requests
import 'ace-builds/src-min-noconflict/theme-eclipse'
import 'ace-builds/src-min-noconflict/mode-assembly_x86'
import 'ace-builds/src-min-noconflict/mode-batchfile'
import 'ace-builds/src-min-noconflict/mode-csharp'
import 'ace-builds/src-min-noconflict/mode-css'
import 'ace-builds/src-min-noconflict/mode-c_cpp'
import 'ace-builds/src-min-noconflict/mode-html'
import 'ace-builds/src-min-noconflict/mode-java'
import 'ace-builds/src-min-noconflict/mode-javascript'
import 'ace-builds/src-min-noconflict/mode-lisp'
import 'ace-builds/src-min-noconflict/mode-python'
import 'ace-builds/src-min-noconflict/mode-sql'
import 'ace-builds/src-min-noconflict/mode-typescript'
import 'ace-builds/src-min-noconflict/mode-xml'

@Component
export default class BaseExamVisualization extends Vue {
  @Prop()
  readonly exam!: Exam

  @Prop(String)
  readonly matNr!: string

  readonly textAreas: { [key: number]: ace.Ace.Editor } = {}
  readonly singleChoices: string[] = []
  readonly multiChoices: string[][] = []

  finalSubmitText = '(You can test this button, no real submit will be performed)'

  inputStudentName = ''
  submissionMode = false

  // Exam should only be set once, otherwise the editors setup would be executed twice...
  @Watch('exam', { immediate: true })
  onExamChanged (newVal : Exam, oldVal : Exam | null) {
    if (!oldVal && newVal) {
      const vm = this
      // Wait with code execution until base editors are added to the DOM
      Vue.nextTick(() => {
        if (vm.exam) {
          for (let i = 0; i < vm.exam.blocks.length; i++) {
            vm.installAceEditor(i)
            vm.loadDataFromLocalStorage(i)
            vm.installLocalStorageBackup(i)
          }
        }
      })
    }
  }

  async submit (): Promise<void> {
    await this.$bvModal.msgBoxOk(
      'Your exam would have been submitted now.\n' +
        'You should now inform your exam supervisor via chat that you finished.\n' +
        'Afterwards you could leave the exam.',
      { title: 'Exam submitted (No real submission performed on demo page)' }
    )
    setTimeout(() => {
      window.location.replace('/thanks.html')
    }, 1000)
  }

  aceOptions (mode: string): Partial<ace.Ace.EditorOptions> {
    return {
      theme: 'ace/theme/eclipse',
      mode: mode,
      minLines: 10,
      maxLines: 1000,
      wrap: true,
      autoScrollEditorIntoView: true,
      tabSize: 2,
      useSoftTabs: true,
      navigateWithinSoftTabs: true
    }
  }

  installLocalStorageBackup (index: number) {
    const vm = this
    const block = vm.exam.blocks[index]
    // Update local storage after every letter that gets written
    const localStorageKey = vm.exam.key + index

    switch (block.kind) {
      case 'TEXT_QUESTION':
      case 'CODE_QUESTION':
        vm.textAreas[index].on('change', function () {
          localStorage.setItem(localStorageKey, vm.textAreas[index].getValue())
        })
        break
      case 'IMAGE_QUESTION':
        // TODO
        break
      case 'SINGLE_CHOICE_QUESTION':
        // Done via singleChoiceLocalStorageBackup
        break
      case 'MULTIPLE_CHOICE_QUESTION':
        // Done via multiChoiceLocalStorageBackup
        break
    }
  }

  loadDataFromLocalStorage (index: number) {
    const vm = this
    const block = vm.exam.blocks[index]
    // Load data from local storage (for example, when re-opening the browser window)
    const localStorageKey = vm.exam.key + index
    const storedValue = localStorage.getItem(localStorageKey)

    if (storedValue) {
      switch (block.kind) {
        case 'TEXT_QUESTION':
        case 'CODE_QUESTION':
          vm.textAreas[index].setValue(storedValue, -1)
          break
        case 'IMAGE_QUESTION':
          // TODO
          break
        case 'SINGLE_CHOICE_QUESTION':
          vm.singleChoices[index] = storedValue
          break
        case 'MULTIPLE_CHOICE_QUESTION':
          vm.multiChoices[index] = JSON.parse(storedValue)
          break
      }
    }
  }

  installAceEditor (blockIdx: number): void {
    const vm = this
    if (vm.exam) {
      const block = vm.exam.blocks[blockIdx]
      const id = 'aceeditor_' + blockIdx
      // Init code editor
      if (block.kind === 'TEXT_QUESTION' || block.kind === 'CODE_QUESTION') {
        let mode = ''
        if (block.kind === 'CODE_QUESTION') {
          mode = vm.getMode(block.codeLanguage!)
        }
        const aceEditor = ace.edit(id, vm.aceOptions(mode))
        // aceEditor.setShowInvisibles(true)
        aceEditor.setShowPrintMargin(false)

        vm.textAreas[blockIdx] = aceEditor
        // Copy paste blocking (disabled)
        /*  block.editor.on(
          "beforeChange",
          function (_, change) {
            if (change.origin === "paste") {
              change.cancel();
            }
          }); */
      }
    }
  }

  getMode (codeLanguage: CodeLanguage): string {
    switch (codeLanguage) {
      case 'ASM':
        return 'ace/mode/assembly_x86'
      case 'C':
        return 'ace/mode/c_cpp'
      case 'CSS':
        return 'ace/mode/css'
      case 'C_PLUS_PLUS':
        return 'ace/mode/c_cpp'
      case 'C_SHARP':
        return 'ace/mode/csharp'
      case 'HTML':
        return 'ace/mode/html'
      case 'JAVA':
        return 'ace/mode/java'
      case 'JAVASCRIPT':
        return 'ace/mode/javascript'
      case 'LISP':
        return 'ace/mode/lisp'
      case 'PYTHON':
        return 'ace/mode/python'
      case 'SHELL':
        return 'ace/mode/batchfile'
      case 'SQL':
        return 'ace/mode/sql'
      case 'TYPESCRIPT':
        return 'ace/mode/batchfile'
      case 'XML':
        return 'ace/mode/xml'
    }
  }

  singleChoiceLocalStorageBackup (index: number, selected: string) {
    const vm = this
    const localStorageKey = vm.exam.key + index
    localStorage.setItem(localStorageKey, selected)
  }

  multiChoiceLocalStorageBackup (index: number, selected: string[]) {
    const vm = this
    const localStorageKey = vm.exam.key + index
    localStorage.setItem(localStorageKey, JSON.stringify(selected))
  }
}
