import 'reflect-metadata' // https://github.com/typestack/class-transformer#installation
import Vue from 'vue'
import axios from 'axios'

import {
  BootstrapVue,
  BIconArrowUp,
  BIconArrowDown,
  BIconX,
  BIconExclamationTriangleFill,
  BIconInfo
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './style.css'

Vue.use(BootstrapVue)
Vue.component('BIconArrowUp', BIconArrowUp)
Vue.component('BIconArrowDown', BIconArrowDown)
Vue.component('BIconX', BIconX)
Vue.component('BIconExclamationTriangleFill', BIconExclamationTriangleFill)
Vue.component('BIconInfo', BIconInfo)

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'production') {
  axios.defaults.baseURL = 'http://localhost:8081'
  axios.defaults.withCredentials = true
}
