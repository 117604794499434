import { Type } from 'class-transformer'

export class Participant {
    public examKey: string
    public matNr: string
    public skz: string
    public firstName: string
    public lastName: string
    public mail: string
    /** This is injected on the backend and does not have to be set / entered on the frontend */
    public linkCode?: string

    constructor (examKey: string = '',
      matNr: string = '',
      skz: string = '',
      firstName: string = '',
      lastName: string = '',
      mail: string = '',
      linkCode: string = '') {
      this.examKey = examKey
      this.matNr = matNr
      this.skz = skz
      this.firstName = firstName
      this.lastName = lastName
      this.mail = mail
      this.linkCode = linkCode
    }
}

export class Group {
    public key: string
    public supervisorMail: string
    public streamLink: string
    @Type(() => Participant)
    public participants: Participant[]

    constructor (
      key: string = '',
      supervisorMail: string = '',
      streamLink: string = '',
      participants: Participant[] = []
    ) {
      this.key = key
      this.supervisorMail = supervisorMail
      this.streamLink = streamLink
      this.participants = participants
    }
}
